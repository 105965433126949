<template>
  <div class="wrap">
    <div class="content">
      <div class="contentFile">
        <ViewVideo :lessonObj="lesson" v-if="lesson.type == 'video'" @videoEnded="videoEnded"></ViewVideo>
        <ViewPDF :lessonObj="lesson" v-if="lesson.type == 'pdf'"></ViewPDF>
      </div>

      <div class="contentTitle">
        <span>{{ course.name }}</span> <br />
        <b>{{ lesson.name }}</b>
      </div>
      <div class="contentDescription">
        <div v-html="lesson.description"></div>
      </div>
    </div>

    <div class="lesson">
      <div class="lessonTitle">Lessons</div>
      <div class="lessonList">
        <div class="card">
          <div class="card-body" id="lessons-list">
            <div v-for="(topic, index) in course.modules" :key="index">
              <ul class="list-group list-group-flush">
                <li class="list-group-item list-group-item-action" style="background-color: #eee">
                  <b>{{ topic.name }}</b>
                </li>
              </ul>
              <div class="list-group list-group-flush">
                <router-link
                  v-for="lecture in topic.children"
                  :key="lecture.uuid"
                  :to="{ name: 'Class', params: { courseSlug: course.slug, lessonUUID: lecture.uuid } }"
                  class="list-group-item list-group-item-action"
                  :class="lecture.uuid == lesson.uuid ? 'active' : ''"
                  style="text-decoration: none"
                >
                  <table style="width: 100%">
                    <tr>
                      <td width="5">
                        <i class="icon-film mr-3" v-if="lecture.type == 'video'"></i>
                        <i class="icon-file-pdf mr-3" v-if="lecture.type == 'pdf'"></i>
                        <i class="icon-question-circle mr-3" v-if="lecture.type == 'quiz'"></i>
                      </td>
                      <td class="lectureName">{{ lecture.name }}</td>
                      <!-- <td width="5">
                      <span v-if="lecture.type != 'pdf'">{{ lecture.duration }}</span>
                    </td> -->
                    </tr>
                  </table>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewVideo from "@/components/ViewVideo.vue";
import ViewQuiz from "@/components/ViewQuiz.vue";
import ViewPDF from "@/components/ViewPDF.vue";
import axios from "axios";

export default {
  data() {
    return {
      course: {},
      lesson: {},
    };
  },

  components: {
    ViewVideo,
    ViewPDF,
    ViewQuiz,
  },

  watch: {
    "$route.params": function (params) {
      this.loadLesson(params.courseSlug, params.lessonUUID);
      this.$forceUpdate();
    },
  },

  beforeMount() {
    this.loadLesson(this.$route.params.courseSlug, this.$route.params.lessonUUID);
  },

  methods: {
    loadLesson(courseSlug, lessonUUID) {
      const endpoint = `/candidate/lesson/${courseSlug}/${lessonUUID}`;
      axios.get(endpoint).then((res) => {
        this.course = res.data.course;
        this.lesson = res.data.lesson;
      });
    },

    videoEnded(completeLessonUUID) {
      const endpoint = `/candidate/lesson/${this.$route.params.courseSlug}/${completeLessonUUID}`;
      axios.post(endpoint).then((res) => {
        console.log(res.data);
      });
      if (this.lesson.nextLesson != "") {
        this.$router.push({
          name: "Class",
          params: {
            courseSlug: this.course.slug,
            lessonUUID: this.lesson.nextLesson,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.content {
  background-color: #ebeef0;
}
.lesson {
  background-color: #fff;
}

/* larger screen */
@media (min-width: 992px) {
  .wrap {
    display: flex;
  }
  .content,
  .lesson {
    height: 100vh;
    padding-top: 64px;
  }
  .content {
    width: 70vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .lesson {
    width: 30vw;
  }
  .lessonList {
    height: 80vh;
  }
  .lessonList .lectureName {
    font-size: 0.8rem;
  }
}
/* medium */
@media (min-width: 768px) and (max-width: 991.98px) {
  .wrap {
    display: flex;
  }
  .content,
  .lesson {
    height: 100vh;
    padding-top: 64px;
  }
  .content {
    width: 70vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .lesson {
    width: 30vw;
  }
  .lessonList {
    height: 80vh;
  }
  .contentFile {
    padding: 0 50px;
  }
  .lessonList .lectureName {
    font-size: 0.7rem;
  }
}
/* small screen */
@media (max-width: 576px) {
  .content,
  .lesson {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content {
    height: 65vh;
    width: 100vw;
    padding-top: 56px;
  }
  .lesson {
    height: 35vh;
    width: 100vw;
    border-top: #eee solid 1px;
  }
  .lessonList {
    height: 40vh;
  }
  .lessonList .lectureName {
    font-size: 0.8rem;
  }
}

.contentTitle {
  width: 100%;
  display: block;
  position: sticky;
  color: #fff;
  padding: 12px 0;
  background-color: #333;
  text-align: center;
}
.contentTitle span {
  color: #16994c;
  font-size: 12px;
}

.contentDescription {
  width: 100%;
  height: auto;
  padding: 20px 30px;
  font-size: 13px;
}

.lessonTitle {
  width: 100%;
  display: block;
  position: sticky;
  padding: 12px 0;
  background-color: #333;
  text-align: center;
  color: #eee;
  text-transform: uppercase;
}
.lessonList {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.lessonList li.list-group-item {
  font-size: 11px;
  padding: 0.4rem 0.5rem;
  text-transform: uppercase;
}

/* 


 */
table {
  margin-bottom: 0 !important;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #16994c;
  border-color: #16994c;
}
.card {
  border-top: none;
  border-bottom: none;
  border-radius: 0.25rem;
  border-radius: 0;
}
</style>