<template>
  <div class="center-to-page">
    <div class="container">
      <div class="row">
        <!-- <div class="col-4 col-3 mt-5"></div> -->
        <div class="col-md-6 offset-md-3 mt-5">
          <div class="card">
            <div class="card-body bg-light">
              <div class="alert alert-warning" v-show="false">Your session expired. Please login again.</div>
              <p class="login-box-msg">Please enter your email and password.</p>

              <form @submit.prevent="login">
                <div class="form-group mb-3">
                  <input v-model="user.email" type="text" class="form-control form-control-lg" placeholder="Email or Username" :class="{ 'is-invalid': $v.user.email.$error, 'is-valid': !$v.user.email.$invalid }" />
                  <!-- <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fa fa-envelope"></span>
                    </div>
                  </div>-->
                  <div class="invalid-feedback">
                    <span v-if="!$v.user.email.required">Email or Username is required.</span>
                    <!-- <span v-if="!$v.user.email.email">Email must be valid email address.</span> -->
                  </div>
                </div>
                <div class="form-group mb-3">
                  <input v-model="user.password" type="password" class="form-control form-control-lg" placeholder="Password" :class="{ 'is-invalid': $v.user.password.$error, 'is-valid': !$v.user.password.$invalid }" />
                  <!-- <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fa fa-lock"></span>
                    </div>
                  </div>-->
                  <div class="invalid-feedback">
                    <span v-if="!$v.user.password.required">Password is required.</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <button type="submit" class="btn btn-danger btn-lg" :disabled="isDisabled">{{ submitButton }}</button>
                  </div>
                  <div class="col-6 text-right">
                    <!-- <router-link :to="{ name: 'SignUp' }">Sign up</router-link>
                    <br />
                    <router-link to="/password-reset">Forgot Password?</router-link>
                    <br /> -->
                  </div>
                  <!-- /.col -->
                </div>
              </form>
            </div>
            <!-- /.login-card-body -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth from "../helper/auth";
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      submitButton: "Sign in",
      isDisabled: false,
      submitted: false,
    };
  },

  methods: {
    login() {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isDisabled = false;
        return;
      }
      this.submitted = true;
      this.isDisabled = true;
      this.submitButton = "Authenticating...";

      let form = new FormData();
      form.append("email", this.user.email);
      form.append("password", this.user.password);
      axios
        .post("/auth/login", form)
        .then((res) => {
          if (res.data.status == "success") {
            this.$store.state.authUser = res.data.user;
            this.$store.state.authToken = res.data.token;
            this.$store.state.loggedIn = true;
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
            window.localStorage.setItem("log_uuid", res.data.log); // this is auth log uuid for server to retrieve JWT token
            //this.$router.push(res.data.user.roleSlug);
            this.$router.push({ name: "Home" });
            return;
          } else {
            this.$store.state.authUser = {};
            this.isDisabled = false;
            this.submitButton = "Sign in";
            this.$swal("", res.data.message, "error");
          }
        })
        .catch((err) => {
          this.$swal("", "Unable to send request.", "error");
          this.isDisabled = false;
          this.submitButton = "Sign in";
        });
    },
  },

  computed: {
    client() {
      return this.$store.state.client;
    },
  },

  mounted() {
    document.title = "Login";
    //this.$store.dispatch("getClient");
  },

  validations: {
    user: {
      //email: { required, email },
      email: { required },
      password: { required },
    },
  },
};
</script>




<style  scoped>
/* .Login {
  margin: 0;
  padding: 0;
}
.login-box,
.register-box {
  width: 360px;
}
*/
.login-logo,
.register-logo {
  font-size: 2.1rem;
  font-weight: 300;
  margin-bottom: 0.9rem;
  text-align: center;
}
.login-logo img {
  display: inline-block;
}
.login-logo a {
  color: #444;
}
.login-logo a:hover {
  text-decoration: none;
}
/*
@media (max-width: 576px) {
  .login-box,
  .register-box {
    margin-top: 0;
    width: auto;
  }
}
.login-box {
  margin: 0 !important;
}
.register-logo {
  font-size: 1.4rem;
} */
</style>
