<template>
  <div class="ViewVideo" v-on:contextmenu="disableRightClick">
    <vue-plyr ref="plyr">
      <video :src="lessonObj.contentLink" :autoplay="myAutoplay" :poster="lessonObj.course.image" controls allowfullscreen allowtransparency preload="auto">
        <source :src="lessonObj.contentLink" type="video/mp4" />
      </video>
    </vue-plyr>
  </div>
</template>

<script>
export default {
  name: "ViewVideo",
  data() {
    return {
      myAutoplay: false,
    };
  },
  props: {
    lessonObj: {},
  },
  computed: {
    player() {
      return this.$refs.plyr.player;
    },
  },
  mounted() {
    this.player.on("ended", () => {
      this.myAutoplay = true;
      this.$emit("videoEnded", this.lessonObj.uuid);
    });
  },
  methods: {
    disableRightClick(e) {
      e.preventDefault();
    },
  },
};
</script>

<style scoped>
</style>