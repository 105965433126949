<template>
  <div class="ViewPDF" v-on:contextmenu="disableRightClick">
    <pdf v-for="i in numPages" :key="i" :src="src" :page="i" style="display: inline-block; width: 100%"></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      //src: pdf.createLoadingTask(this.lessonObj.contentLink),
      numPages: undefined,
    };
  },
  computed: {
    lesson() {
      return this.lessonObj;
    },
    src() {
      return pdf.createLoadingTask(this.lessonObj.contentLink);
    },
  },
  props: {
    lessonObj: {},
  },
  mounted() {
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });
  },
  methods: {
    disableRightClick(e) {
      e.preventDefault();
    },
  },
};
</script>

