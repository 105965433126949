
<template>
  <div class="PageNotFound">
    <div class="row mt-5">
      <div class="col-md-8 offset-md-2 mt-5 text-center">
        <h1 class="mt-5 pt-5">Page not found.</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
