<template>
  <div class="ViewQuiz" v-on:contextmenu="disableRightClick">
    <p class="mt-0 mb-0">
      <b>Lesson:</b>
      {{lessonObj.name}}
    </p>

    <div class="wrapper">
      <!-- Nav button -->
      <!-- <div class="row">
        <div class="col-12">
          <div class="buttonWrapper" v-if="questions.length > 0">
            <button
              v-for="(question, index) in questions"
              :key="index"
              @click="changeQustion(question, index)"
              class="btn btn-primary btn-sm mr-2"
            >{{index + 1}}</button>
          </div>
          <div class="emptyQuestions" v-if="questions.length < 1">No question available.</div>
        </div>
      </div>-->

      <div class="row">
        <div class="col-12">
          <div class="buttonWrapper">
            <button @click="navButton('prev')" class="btn btn-primary btn-sm mr-2" v-if="questions.length > 0">
              <i class="fa fa-chevron-left"></i> Prev
            </button>
            <button @click="navButton('next')" class="btn btn-primary btn-sm mr-2 text-right" v-if="questions.length > 0">
              Next
              <i class="fa fa-chevron-right"></i>
            </button>
            <div class="emptyQuestions" v-if="questions.length < 1">No question available.</div>
          </div>
        </div>
      </div>

      <!-- question -->
      <div class="row" v-if="questionObj != null">
        <div class="col-12">
          <b class="text-danger">Question #{{questionIndex + 1}} of {{this.questions.length}}</b>
          <div v-html="questionObj.content"></div>
          <div v-for="(answer, index) in questionObj.answers" :key="index">
            <table class="table">
              <tr>
                <td width="10">
                  <input type="radio" :name="answer.uuid" />
                </td>
                <td>
                  <div v-html="answer.content"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ViewQuiz",

  data() {
    return {
      questionObj: null,
      questionIndex: 0
    };
  },

  props: {
    lessonObj: {},
    questions: Array
  },

  mounted() {
    this.changeQustion(this.questions[0], 0);
  },

  methods: {
    navButton(pos) {
      if (pos == "prev") {
        if (this.questionIndex > 0) {
          this.questionIndex--;
          this.questionObj = this.questions[this.questionIndex];
        }
      } else if (pos == "next") {
        if (this.questionIndex < this.questions.length - 1) {
          this.questionIndex++;
          this.questionObj = this.questions[this.questionIndex];
        }
      }
      //console.log(this.questionIndex);
    },

    changeQustion(question, index) {
      this.questionObj = question;
      this.questionIndex = index;
    },

    disableRightClick(e) {
      e.preventDefault();
    }
  },

  watch: {
    questions: function(newValue, oldValue) {
      this.changeQustion(this.questions[0], 0);
      this.questionIndex = 0;
    }
  }
};
</script>

<style scoped>
.wrapper {
  text-align: left;
  padding: 2rem;
  font-size: 1.5rem;
}
.buttonWrapper,
.emptyQuestions {
  width: 100%;
  background-color: #eee;
  padding: 2rem;
  margin-bottom: 20px;
}
.emptyQuestions {
  font-size: 2rem;
  text-align: center;
  color: #666;
}
.btn-sm {
  min-width: 35px !important;
  margin-top: 2px;
}
</style>