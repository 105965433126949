import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Bootstrap Vue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// vue-progressbar
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: '#16994C',
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})

//axios
import axios from 'axios'
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.VUE_APP_API_KEY;
axios.defaults.headers.common['apikey'] = process.env.VUE_APP_API_KEY; // because the nuxt app bearer token issue

// Validate 132kb
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// Sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; // 41 KB
Vue.use(VueSweetalert2);

// VuePlyr
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: true },
    //debug: true,
  },
  emit: ['ended']
})

// filter
import './helper/filters'
import './assets/custom.css'
import './assets/font-icons.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')