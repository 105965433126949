import Vue from 'vue'
//import moment from 'moment';

Vue.filter('formatDateTime', function (value) {
    if (value) {
        //return moment(String(value)).format('DD MMM YYYY HH:MM')
        let d = new Date(value);
        let months = ["Jan", "Fed", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let amOrPm = d.getHours() > 12 ? 'pm' : 'am';
        let format = d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes() + amOrPm;
        return format
    }
});

Vue.filter('formatDate', function (value) {
    if (value) {
        //return moment(String(value)).format('DD MMM YYYY HH:MM')
        let d = new Date(value);
        let months = ["Jan", "Fed", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //let amOrPm = d.getHours() > 12 ? 'pm' : 'am';
        let format = d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear();
        return format
    }
});

Vue.filter('thousandSeparator', function (value, precision) {
    if (value) {
        //return Number((value).toFixed(precision));
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
});


Vue.filter('titleCase', function (value) {
    if (value) {
        value = value.toLowerCase().split(' ');
        for (var i = 0; i < value.length; i++) {
            value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
        }
        return value.join(' ');
    }
});


Vue.filter('pluralize', function (value, precision) {
    if (value) {
        let lastChar = value[value.length - 1]
        if (lastChar == 'y') {
            return value.substring(0, value.length - 1) + 'ies'
        }
        return value + 's'
    }
});

Vue.filter('currency', function (value, precision) {
    if (value) {
        return '₦' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 'Free'
});


Vue.filter('duration', function (value) {
    if (!value) {
        return "N/A"
    }
    let valueInMin = value
    let valueInHour = valueInMin / 60

    if (valueInHour < 1) {
        return Math.floor(valueInMin) + ' mins' // 3.8 to 3 valueInHour
    } else if (valueInHour == 1) {
        return Math.floor(valueInHour) + ' hour' // 3.8 to 3 valueInHour
    } else if (valueInHour > 1) {
        return Math.floor(valueInHour) + ' hours' // 3.8 to 3 valueInHour
    } else if (Math.floor(valueInHour) == 24) {
        return "1 day"
    } else if (Math.floor(valueInHour) > 24) {
        return Math.floor(valueInHour / 24) + ' days'
    }

    //return Math.floor(valueInHour)      // 3.8 to 3 valueInHour

})
