import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../helper/auth'
import Home from '../views/Home.vue'
import Class from '../views/Class.vue'
import Login from '../views/Login.vue'
import PageNotFound from '../views/PageNotFound.vue'
//import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: auth.ifAuthenticated,
  },

  {
    path: '/class/:courseSlug/:lessonUUID',
    name: 'Class',
    component: Class,
    beforeEnter: auth.ifAuthenticated,
  },

  {
    path: '/login',
    name: 'Login',
    beforeEnter: auth.ifNotAuthenticated,
    component: Login
  },

  {
    path: '/*',
    name: 'PageNotFound',
    component: PageNotFound
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
