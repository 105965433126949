<template>
  <div>
    <div class="jumbotron">
      <h1 class="display-4 fixed-navbar-padding">My Courses</h1>
      <!-- <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p> -->
    </div>

    <div class="container clearfix">
      <div class="row col-mb-50" v-if="courses.length == 0">
        <div class="col-md-12"><p>You will find your in-progress courses here.</p></div>
      </div>
      <div class="row col-mb-50" v-else>
        <div class="col-md-4" v-for="(course, index) in courses" :key="index">
          <!-- My course -->
          <div class="course-block">
            <router-link :to="{ name: 'Class', params: { courseSlug: course.Slug, lessonUUID: course.LessonUUID } }">
              <div class="course-img">
                <img :src="course.Image" alt="" class="img-fluid" />
              </div>

              <div class="course-content">
                <div class="course-meta">
                  <span class="course-student"><i class="icon-time"></i>{{ course.Duration | duration }}</span>
                  <span class="course-duration"><i class="icon-files"></i>{{ course.TotalLectures }} Lectures</span>
                </div>
                <!-- <span class="course-cat">progress</span> -->
                <h4>{{ course.Name }}</h4>
                <!-- <p>{{ course.Introduction }}</p> -->
                <div class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 25%; background-color: #16994c" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                </div>
              </div>
            </router-link>
          </div>
          <!--  end My course -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      courses: [],
    };
  },

  beforeMount() {
    axios.get("/candidate/my-courses").then((res) => {
      this.courses = res.data.courses;
    });
  },
};
</script>

<style scoped>
/* .container {
  padding-top: 64px;
} */
</style>